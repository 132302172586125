import React, { memo, useEffect, useState, useCallback } from 'react';
import { PrescriptionJobProvider } from 'js/components/Prescription/PrescriptionJobContext';
import WebAPIUtils from 'js/WebAPIUtils';
import { useFarm } from 'js/context/AccountContext';

import PrescriptionEditorContainer from 'js/components/Prescription/PrescriptionEditorContainer';
import PrescriptionJobDownloadContainer from 'js/components/Prescription/PrescriptionJobDownloadContainer';
import FullScreenPortalPaper from 'js/components/Prescription/FullScreenPortalPaper';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {
  isSatelliteLayer,
  MetaTypes,
  PrescriptionJob,
} from 'js/components/Prescription/PrescriptionJob';
import ViewModeConstants from 'js/constants/ViewModeConstants';
import { setViewMode } from 'js/reducers/ControlReducer';
import { PRESCRIPTION_METERING, PRESCRIPTION_UNIT } from 'js/constants/PrescriptionConstants';
import { connect } from 'react-redux';
import {
  deletePrescriptionDocumentation,
  deletePrescriptionJobs,
  getPrescriptionDocumentation,
  getPrescriptionMaps,
  savePrescriptionJobLocally,
  savePrescriptionJobPromise,
  setCurrentJob,
} from 'js/reducers/PrescriptionReducer';
import PrescriptionSaveDialog from 'js/components/Prescription/Dialogs/PrescriptionSaveDialog';
import { useLangFile } from 'js/context/LanguageContext';
import { selectField, createFieldsMap, resetFilters } from 'js/reducers/FieldReducer';
import PrescriptionArchive from './PrescriptionArchive';
import { useSeasonContext } from '../../context/SeasonContext';
import { selectSeason } from '../../reducers/SeasonReducer';
import PrescriptionArchiveConfirmDeleteDialog from './PrescriptionArchiveConfirmDeleteDialog';
import { resetCurrentJob, setPrescriptionJobSeasonId } from '../../reducers/PrescriptionReducer';
import { formatSeason } from '../../helpers/SeasonUtils';
import { Action, useActionSnackbarContext } from '../ActionSnackbarHandler/ActionSnackbarHandler';
import Season from '../../model/Season';
import { setSelectedSurveyForcefully } from '../../reducers/SurveyReducer';
import { useSelectedSurvey } from '../../context/SurveyContext';
import moment from 'moment';
import { SATELLITE_LAYERS } from '../../constants/SatelliteLayers';
import { setDate } from '../../reducers/FieldReducer';

/* endregion IMPORTS */

const getLatestDate = (fieldStatsArray: Array) => {
  let sortedFieldInfo = fieldStatsArray.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });

  return sortedFieldInfo[0].date;
};

const mapStateToProps = (store) => ({
  maps: store.prescription.maps,
  documentation: store.prescription.documentation,
  currentJob: store.prescription.currentJob,
  viewMode: store.control.viewMode,
  images: store.field.images,
  date: store.field.date,
  selectedField: store.field.selectedField,
  selectedLayer: store.survey.selectedLayer,
  showSatelliteImagery: store.field.showSatelliteImagery,
  showSoilSurveys: store.survey.showSoilSurveys,
});

const PrescriptionContainer = ({
  dispatch,
  maps,
  documentation,
  currentJob,
  images,
  viewMode,
  date,
  selectedField,
  selectedLayer,
  showSatelliteImagery,
  showSoilSurveys,
}) => {
  const LangFile = useLangFile();
  const { selectedSeason, seasons } = useSeasonContext();
  const selectedSurvey = useSelectedSurvey();

  const [fields, setFields] = useState(null);

  const [showArchive, setShowArchive] = useState(false);
  const [loadingMaps, setLoadingMaps] = useState(false);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [loadingJob, setLoadingJob] = useState(false);
  const [downloadJob, setDownloadJob] = useState(null);
  const [savingJob, setSavingJob] = useState(false);
  const [savingError, setSavingError] = useState(false);
  const [deleteJob, setDeleteJob] = useState(null);

  const [fieldStats, setFieldStats] = useState(null);
  const [datesWithImages, setDatesWithImages] = useState(null);

  const { addAction } = useActionSnackbarContext();

  const setPrescriptionJob = useCallback((changes, recalculate) => {
    dispatch(setCurrentJob({ ...changes, saved: false }, recalculate));
  });

  const farm = useFarm();

  useEffect(() => {
    if (!selectedField || !selectedField.bundle) {
      return;
    }
    const bundleId = selectedField.bundle;
    WebAPIUtils.getBundleStats(bundleId, 'ndvi').then((result) => {
      if (!result || result.length === 0) {
        return;
      }

      setFieldStats(result);
      const dates = result.map((stat) => stat.date);
      setDatesWithImages(dates);
      const imageExists = dates.includes(date);

      if (imageExists) {
        return;
      }

      const latestDateWithImage = getLatestDate(result);
      dispatch(setDate(latestDateWithImage));
      const job = { ...currentJob, date: new Date(latestDateWithImage) };
      setPrescriptionJob(job);
    });
  }, []);

  useEffect(() => {
    setShowArchive(viewMode === ViewModeConstants.PRESCRIPTION_ARCHIVE);
  }, [viewMode]);

  useEffect(() => {
    if (!images) {
      return;
    }
    const imgHasInfo = images.get(moment(date).format('YYYY-MM-DD'));
    if (!fieldStats || fieldStats.length === 0 || !imgHasInfo) {
      return;
    }

    const job = {
      ...currentJob,
      assets: { ...currentJob.assets, ...createJobAssets(new Date(date), currentJob.fieldId) },
    };

    setPrescriptionJob(job);
  }, [images, date]);

  const createJobAssets = (date: Date, fieldId: number) => {
    const currentImages = images.get(moment(date).format('YYYY-MM-DD'));
    const fieldImages = currentImages && currentImages.find((image) => image.field_id === fieldId);

    if (fieldImages === null || fieldImages === undefined) {
      return;
    }

    let assets = {};

    if (fieldImages.hasOwnProperty(SATELLITE_LAYERS.VITALITY_NDVI)) {
      assets = {
        ...assets,
        ...{ [SATELLITE_LAYERS.VITALITY_NDVI]: fieldImages[SATELLITE_LAYERS.VITALITY_NDVI] },
      };
    }

    if (fieldImages.hasOwnProperty(SATELLITE_LAYERS.VARIATIONS_NDVI)) {
      assets = {
        ...assets,
        ...{ [SATELLITE_LAYERS.VARIATIONS_NDVI]: fieldImages[SATELLITE_LAYERS.VARIATIONS_NDVI] },
      };
    }

    if (fieldImages.hasOwnProperty(SATELLITE_LAYERS.VISIBLE)) {
      assets = {
        ...assets,
        ...{ [SATELLITE_LAYERS.VISIBLE]: fieldImages[SATELLITE_LAYERS.VISIBLE] },
      };
    }

    return assets;
  };

  useEffect(() => {
    if (selectedSeason) {
      WebAPIUtils.getSeasonFields(selectedSeason.id).then((result) => {
        if (result && result.fields) {
          setFields(createFieldsMap(result.fields));
        }
      });
    }
  }, [selectedSeason]);

  // Create new Prescription Job
  useEffect(() => {
    if (viewMode === ViewModeConstants.PRESCRIPTION) {
      if (selectedField) {
        if (!loadingJob && !currentJob) {
          let job = new PrescriptionJob();
          job.seasonId = selectedSeason.id;
          job.fieldId = selectedField.fieldId;
          job.fieldSize = selectedField.size;
          job.bounds = selectedField.bounds;
          job.date = new Date(date);
          job.unit = PRESCRIPTION_UNIT.KILOGRAM;
          job.metaType = MetaTypes.FERTILIZING; // Default
          job.metering = PRESCRIPTION_METERING.HA; // Default
          job.saved = true; // true to allow faster exits when selecting source, will be set to false when source is selected

          if (showSatelliteImagery) {
            job.layer = SATELLITE_LAYERS.VITALITY_NDVI;
          } else if (showSoilSurveys) {
            job.layer = selectedLayer;
          }

          if (selectedSurvey && selectedSurvey.newImages) {
            job.survey = selectedSurvey;
            job.assets = { ...job.assets, ...selectedSurvey.newImages };
          }

          if (images) {
            job.assets = { ...job.assets, ...createJobAssets(job.date, job.fieldId) };
          }
          setPrescriptionJob(job);
        }
      }
    }
  }, [
    viewMode,
    currentJob,
    images,
    loadingJob,
    selectedField,
    date,
    selectedSeason,
    selectedSurvey,
  ]);

  // Load Prescription Maps for Archive
  useEffect(() => {
    if (showArchive && fields != null) {
      setLoadingMaps(true);

      Promise.all([
        dispatch(getPrescriptionMaps(farm, fields)),
        dispatch(getPrescriptionDocumentation(farm.farmId)),
      ])
        .then(() => {
          setLoadingMaps(false);
        })
        .catch((e) => {
          setLoadingMaps(false);
        });
    }
  }, [farm, showArchive, fields]);

  // DOWNLOADING PRESCRIPTION JOBS
  const fetchJobWithValues = (job: PrescriptionJob, saveFirst: boolean) => {
    return new Promise(async (resolve, reject) => {
      let result: PrescriptionJob = job;

      if (saveFirst) {
        try {
          setSavingJob(true);
          setSavingError(false);

          result = await savePrescriptionJobPromise(result, farm);

          dispatch(savePrescriptionJobLocally(result));
          setPrescriptionJob(result);

          setSavingError(false);
          setSavingJob(false);
        } catch (e) {
          setSavingError(true);
          setSavingJob(false);
          setLoadingJob(false);

          reject(new Error('Failed to save the VRM.'));
          return;
        }
      }

      // Save went well or was skipped
      if (isSatelliteLayer(result.layer)) {
        let data = await WebAPIUtils.getVariableRateMap(result.jobId);

        // required to make sure we have the required TIFF available for creating the VRM backend
        // let payload = await dispatch(fetchCropHealth(farm.farmId, moment(data.date).format("YYYY-MM-DD"), fields));
        // if (payload.value && payload.value.length > 0) {
        result = PrescriptionJob.fromNdviData(data, fields);
        // }
        // else {
        //   setSavingJob(false);
        //   setLoadingJob(false);
        //
        //   reject(new Error("The required TIFF was not found for this VRM."));
        //   return;
        // }
      } else {
        let data = await WebAPIUtils.getPrescriptionJob(farm, result);
        result = PrescriptionJob.fromSurveyData(data, fields);
      }

      setSavingJob(false);
      setLoadingJob(false);
      resolve(result);
    });
  };

  const onResumeJob = useCallback(
    (resumeJob: PrescriptionJob) => {
      setShowArchive(false);

      let field = fields.get(resumeJob.fieldId);

      fetchJobWithValues(resumeJob)
        .then((result) => {
          dispatch(
            setSelectedSurveyForcefully({ surveyId: 'RESUMED-JOB', newImages: result.images })
          );
          setPrescriptionJob(result);
          dispatch(selectField(field));
          dispatch(setViewMode(ViewModeConstants.PRESCRIPTION));
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [fields, farm]
  );

  const onSetSeason = useCallback(
    (job: PrescriptionJob, seasonId: number) => {
      let season = seasons.find((s: Season) => s.id === seasonId);

      dispatch(setPrescriptionJobSeasonId(farm, job, seasonId))
        .then(() => {
          let message = `${LangFile.PrescriptionContainer.job} ${job.jobName} ${LangFile.PrescriptionContainer.successfullyMoved} ${LangFile.PrescriptionContainer.to} ${formatSeason(season, LangFile)}`;
          addAction(new Action(job.jobId, message, 'success', 'filled'));
        })
        .catch((e) => {
          console.error(e);
          let message = `${LangFile.PrescriptionContainer.failedToMove} ${LangFile.PrescriptionContainer.job} ${job.jobName} ${LangFile.PrescriptionContainer.to} ${formatSeason(season, LangFile)}`;
          addAction(new Action(job.jobId, message, 'success', 'filled'));
        });
    },
    [fields, farm, seasons]
  );

  const onConfirmDeleteJob = useCallback(() => {
    setDeleteJob((current) => {
      let job = maps.find((m) => current === m.jobId);
      dispatch(deletePrescriptionJobs([job], farm));
      return null;
    });
  }, [farm, maps]);

  const onDownloadJob = useCallback(
    (job: PrescriptionJob, saveFirst) => {
      if (job.legacy) {
        setDownloadJob(job);
      } else {
        fetchJobWithValues(job, saveFirst)
          .then((result) => {
            setDownloadJob(result);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    [fields, farm]
  );

  const onResetDownloadJob = useCallback(() => {
    setDownloadJob(null);
  }, []);

  const onDocumentationUploaded = useCallback(() => {
    dispatch(getPrescriptionDocumentation(farm.farmId));
  }, [farm.farmId]);

  const onDeleteDocumentation = useCallback(
    (file) => {
      return dispatch(deletePrescriptionDocumentation(file.id)).then(() => {
        dispatch(getPrescriptionDocumentation(farm.farmId));
      });
    },
    [farm.farmId]
  );

  const onHideSaveDialog = useCallback(() => {
    setShowSaveDialog(false);
    setSavingError(false);
  }, []);

  const onExit = useCallback(() => {
    dispatch(setViewMode(ViewModeConstants.OVERVIEW));
    dispatch(resetCurrentJob());
    dispatch(selectField(null));
    dispatch(setSelectedSurveyForcefully());
  }, []);

  const onSaveAndExit = useCallback(() => {
    setSavingJob(true);

    if (!currentJob.jobName) {
      currentJob.jobName = LangFile.PrescriptionContainer.unnamed;
    }

    savePrescriptionJobPromise(currentJob, farm)
      .then((job) => {
        dispatch(savePrescriptionJobLocally(job));
        onExit();
      })
      .catch((e) => {
        setSavingJob(false);
        setSavingError(true);
      });
  }, [currentJob, farm, onExit]);

  const onExitSafe = useCallback(() => {
    if (currentJob) {
      if (currentJob.saved) {
        onExit();
      } else {
        setShowSaveDialog(true);
      }
    } else {
      if (downloadJob) {
        onResetDownloadJob();
      } else {
        dispatch(setViewMode(ViewModeConstants.OVERVIEW));
      }
    }
  }, [downloadJob, currentJob]);

  const onSeasonChange = useCallback((seasonId) => {
    dispatch(selectSeason(seasonId));
    dispatch(resetFilters());
  }, []);

  return (
    <FullScreenPortalPaper>
      {loadingJob && (
        <Box
          width={'100%'}
          height={'100%'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}>
          <CircularProgress variant={'indeterminate'} color={'primary'} size={80} thickness={4} />
        </Box>
      )}

      {currentJob && (
        <PrescriptionJobProvider value={[currentJob, setPrescriptionJob]}>
          <PrescriptionEditorContainer
            selectedField={selectedField}
            onDownloadJob={onDownloadJob}
            onExitSafe={onExitSafe}
            onExit={onExit}
            datesWithImages={datesWithImages}
          />
          <PrescriptionSaveDialog
            onExit={onExit}
            onCancel={onHideSaveDialog}
            onSaveAndExit={onSaveAndExit}
            savingJob={savingJob}
            savingError={savingError}
            open={showSaveDialog}
          />
        </PrescriptionJobProvider>
      )}

      {showArchive && fields && (
        <PrescriptionArchive
          maps={maps}
          documentation={documentation}
          loadingMaps={loadingMaps}
          fields={fields}
          onDocumentationUploaded={onDocumentationUploaded}
          onDeleteDocumentation={onDeleteDocumentation}
          onDownload={onDownloadJob}
          onClose={onExitSafe}
          onSetSeason={onSetSeason}
          onEdit={onResumeJob}
          onDelete={setDeleteJob}
          onSeasonChange={onSeasonChange}
        />
      )}

      {downloadJob && (
        <PrescriptionJobDownloadContainer
          legacy={downloadJob.legacy}
          viewMode={viewMode}
          savingJob={savingJob}
          savingError={savingError}
          downloadJob={downloadJob}
          onBack={onResetDownloadJob}
        />
      )}

      <PrescriptionArchiveConfirmDeleteDialog
        open={deleteJob != null}
        onClose={setDeleteJob}
        onConfirm={onConfirmDeleteJob}
      />
    </FullScreenPortalPaper>
  );
};

export default memo(connect(mapStateToProps)(PrescriptionContainer));
