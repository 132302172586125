// @flow

import React, { Fragment, memo, useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { DialogTitle, InputAdornment } from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import { voidFunc } from 'js/constants/PropTypeUtils';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import { DatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import moment from 'moment-timezone';
import { grey } from '@material-ui/core/colors';

import Styles from './DateRangeInput.module.less';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import { useLangFile } from '../../context/LanguageContext';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../hooks/useFirebaseAnalytics';

const renderDatePicker = (state, minDate, showPeriod, value, setValue) => {
  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    let dateClone = moment(date);

    const start = moment(state.startDate);
    const end = moment(state.endDate);

    const dayIsBetween = dateClone.isBetween(start, end);
    const isFirstDay = dateClone.isSame(start, 'day');
    const isLastDay = dateClone.isSame(end, 'day');

    const wrapperClassName = clsx({
      [Styles.Highlight]: dayIsBetween,
      [Styles.StartHighlight]: isFirstDay,
      [Styles.EndHighlight]: isLastDay,
      [Styles.SelectedStart]: isFirstDay && selectedDate.isSame(end, 'date'),
      [Styles.SelectedEnd]: isLastDay && selectedDate.isSame(start, 'date'),
    });

    const dayClassName = clsx(Styles.Day, {
      [Styles.NonCurrentMonthDay]: !dayInCurrentMonth || date.isAfter(moment()),
      [Styles.HighlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {dateClone.format('DD')} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <DatePicker
      autoOk={true}
      orientation={'portrait'}
      variant={'static'}
      openTo={'date'}
      disableFuture={true}
      renderDay={showPeriod ? renderWrappedWeekDay : undefined}
      minDate={minDate}
      value={value}
      onChange={setValue}
    />
  );
};

const DateRangeInput = (props: DateRangeInput.propTypes) => {
  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();
  const [showDialog, setShowDialog] = useState(false);
  const [state, setState] = useState({ startDate: props.startDate, endDate: props.endDate });

  useEffect(() => {
    setState({ startDate: props.startDate, endDate: props.endDate });
  }, [props.startDate, props.endDate]);

  const setStartDate = useCallback((value) => {
    setState((current) => {
      if (moment(value).isAfter(current.endDate)) {
        return { startDate: value, endDate: value };
      } else {
        return { ...current, startDate: value };
      }
    });
  }, []);

  const setEndDate = useCallback((value) => {
    setState((current) => {
      if (moment(value).isBefore(current.startDate)) {
        return { startDate: value, endDate: value };
      } else {
        return { ...current, endDate: value };
      }
    });
  }, []);

  const handleDialogOpen = useCallback(() => {
    setShowDialog(true);
    props.onFocus();
  }, []);

  const handleDialogClose = useCallback(() => {
    setShowDialog(false);
    props.onBlur();
  }, []);

  const commitChange = useCallback(() => {
    let since = moment(state.startDate).startOf('day');
    let until = moment(state.endDate).endOf('day');

    props.onChanged(since, until);
    handleDialogClose();

    analytics.logEvent(FIREBASE_EVENTS.WEATHER_HISTORY_DATE_RANGE, {
      since: moment(state.startDate).format('YYYY-MM-DD'),
      until: moment(state.endDate).format('YYYY-MM-DD'),
    });
  }, [props.onChanged, state, handleDialogClose]);

  const startYear = moment(state.startDate).format('YYYY');
  const endYear = moment(state.endDate).format('YYYY');
  const sameYear = startYear === endYear;

  const startText = moment(state.startDate).format('DD. MMM');
  const endText = moment(state.endDate).format('DD. MMM');
  const value = `${startText} - ${endText}`;

  return (
    <Fragment>
      <Box position={'relative'}>
        <TextField
          variant={props.variant}
          className={Styles.Root}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'} className={Styles.InputAdornment}>
                <DateRange />
              </InputAdornment>
            ),
            classes: { input: Styles.Input },
            readOnly: true,
          }}
          onClick={handleDialogOpen}
          value={value}
        />
        <Box
          position={'absolute'}
          left={0}
          top={0}
          color={grey['500']}
          mx={1.5}
          my={0.5}
          fontSize={12}
          className={Styles.Year}
        >
          {sameYear
            ? `${moment(state.startDate).format('YYYY')}`
            : `${moment(state.startDate).format('YYYY')} - ${moment(state.endDate).format('YYYY')}`}
        </Box>
      </Box>

      <Dialog open={showDialog} maxWidth={'md'} onClose={handleDialogClose}>
        <DialogTitle>{LangFile.DateRangeInput.title}</DialogTitle>
        <DialogContent>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-evenly'}
            alignItems={'center'}
          >
            <Box mr={0.5}>
              <Box align={'center'} color={grey['500']} pb={1}>
                {LangFile.DateRangeInput.from}
              </Box>
              <Paper elevation={1} className={Styles.PaperStart}>
                {renderDatePicker(
                  state,
                  props.minDate,
                  props.showPeriod,
                  state.startDate,
                  setStartDate
                )}
              </Paper>
            </Box>

            <Box mx={0}>
              <Box align={'center'} color={grey['500']} pb={1}>
                {LangFile.DateRangeInput.to}
              </Box>
              <Paper elevation={1} className={Styles.PaperEnd}>
                {renderDatePicker(
                  state,
                  props.minDate,
                  props.showPeriod,
                  state.endDate,
                  setEndDate
                )}
              </Paper>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display={'flex'} width={'100%'} justifyContent={'space-between'} px={2}>
            <Button onClick={handleDialogClose}>{LangFile.DateRangeInput.cancel}</Button>
            <Button onClick={commitChange} color={'primary'} variant={'contained'}>
              {LangFile.DateRangeInput.select}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

DateRangeInput.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  minDate: PropTypes.any,
  onChanged: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  showPeriod: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
};

DateRangeInput.defaultProps = {
  onChanged: voidFunc,
  onBlur: voidFunc,
  onFocus: voidFunc,
  variant: 'outlined',
  showPeriod: false,
};

export default memo(DateRangeInput);
