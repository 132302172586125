export const PRESCRIPTION_METERING = {
  HA: 'HA',
  SQUARE_METER: 'M2',
  TOTAL: 'TOTAL',
};

export const PRESCRIPTION_UNIT = {
  KILOGRAM: 'KILOGRAM',
  LITER: 'LITER',
  PIECES_M2: 'PIECES_M2',
};

export const PRESCRIPTION_OVERRIDES_TOOL = {
  OVERRIDE: 'OVERRIDE',
  EXCEPTION: 'EXCEPTION',
  ERASER: 'ERASER',
};

export const PRESCRIPTION_INTERVAL_COLORS = [
  '#e6194B',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#42d4f4',
  '#f032e6',
  '#bfef45',
  '#fabebe',
];

export const PRESCRIPTION_OVERRIDE_COLORS = [
  '#469990',
  '#e6beff',
  '#9A6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#a9a9a9',
];

export const PRESCRIPTION_OVERRIDE_MIN_BRUSH_SIZE = 2.5;
