import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField/TextField';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';

const styles = (theme) => ({
  formGroup: {
    padding: theme.spacing(1),
    position: 'relative',
    marginTop: 0,
    marginBottom: 0,
    boxSizing: 'border-box',
    '&.disablePadding': {
      padding: 0,
    },
  },
  errorWrapper: {
    position: 'absolute',
    bottom: -theme.spacing(2),
    left: theme.spacing(2),
    minHeight: 12,
    '& p': {
      color: '#f44336',
    },
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  label: {
    fontSize: '1rem',
  },
  textInput: {
    fontSize: '1rem',
  },
  '@media (max-width: 1024px), (max-height: 800px)': {
    title: {
      fontSize: '1rem',
    },
    label: {
      fontSize: '0.75rem',
    },
  },
});

const TextFormControl = ({
  classes,
  className,
  children,
  value,
  acceptEmpty,
  variant,
  defaultValue,
  onError,
  onChange,
  errorText,
  disablePadding,
  title,
  label,
  placeholder,
  autoFocus,
  titleClassName,
  onUpdate,
  type,
  acceptZero,
  endAdornment,
  regexValidation,
  min,
  max,
  helperText,
  ...rest
}) => {
  const textField = useRef(null);
  const [currentValue, setCurrentValue] = useState(defaultValue || '');
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (value !== currentValue || !updated) {
      setCurrentValue(isValueValid(value) ? value : defaultValue || '');
      setUpdated(true);
    }
  }, [value]);

  useEffect(() => {
    onError(!isValueValid(currentValue));
  }, [currentValue, onError]);

  const onKeyUp = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        if (textField.current) {
          textField.current.blur();
        }
      }
    },
    [textField.current]
  );

  const handleChange = useCallback(
    (val) => {
      setCurrentValue(val);

      if (typeof onChange === 'function') {
        onChange(val);
      }
    },
    [onChange]
  );

  const handleUpdate = useCallback(() => {
    if (type === 'number') {
      if (Number(currentValue) === Number(value)) {
        return;
      }
    } else {
      if (!acceptEmpty && currentValue.trim() === '' && defaultValue) {
        setCurrentValue(defaultValue);
        onUpdate(defaultValue);
        return;
      }

      if (currentValue === value) {
        return;
      }
    }

    if (typeof onUpdate !== 'function') {
      return;
    }

    onUpdate(type === 'number' ? Number(currentValue) : currentValue.trim());
  }, [type, value, currentValue, defaultValue]);

  const isValueValid = useCallback(
    (val) => {
      if (val === null || val === undefined) {
        return false;
      }

      if (regexValidation) {
        let search = `${val}`;
        let match = search.match(regexValidation);
        return match && match[0];
      }

      if (type === 'text') {
        if (typeof val !== 'string') {
          return false;
        }

        if (val.trim() === '') {
          return acceptEmpty || (!acceptEmpty && defaultValue);
        }
      }

      if (type === 'number') {
        if ((typeof val === 'string' && val.trim() === '') || Number.isNaN(val)) {
          return acceptEmpty;
        }

        if (val < min || val > max) {
          return false;
        }

        if (Number(val) === 0) {
          return acceptZero;
        }
      }

      return true;
    },
    [type, acceptZero, acceptEmpty]
  );

  const renderEndAdornment = () => {
    let adornment = endAdornment;

    if (!adornment) {
      return null;
    }

    return (
      <InputAdornment
        position="end"
        disablePointerEvents
        className={classes.textInput}
        style={{ whiteSpace: 'nowrap' }}>
        {adornment}
      </InputAdornment>
    );
  };

  let error = !isValueValid(currentValue);

  return (
    <FormControl
      component="fieldset"
      className={`${className} ${classes.formGroup} ${disablePadding ? 'disablePadding' : ''}`}
      fullWidth
      margin={'dense'}>
      {title && (
        <Typography className={`${classes.title} ${titleClassName}`} variant={'h6'}>
          {title}
        </Typography>
      )}
      {label && (
        <FormLabel component="label" className={classes.label}>
          {label}
        </FormLabel>
      )}
      <FormGroup>
        <TextField
          {...rest}
          error={error}
          autoFocus={autoFocus}
          value={currentValue}
          placeholder={`${placeholder || defaultValue}`}
          inputProps={{
            onKeyUp: onKeyUp,
            ref: textField,
          }}
          InputProps={{
            endAdornment: renderEndAdornment(),
            classes: { input: classes.textInput },
          }}
          onChange={(event) => handleChange(event.target.value)}
          onBlur={handleUpdate}
          type={type}
          margin="dense"
          variant={variant}
          helperText={helperText}></TextField>
      </FormGroup>
      {children}
      <div className={classes.errorWrapper}>
        {error && errorText && <FormHelperText>{errorText}</FormHelperText>}
      </div>
    </FormControl>
  );
};

export default memo(withStyles(styles)(TextFormControl));

TextFormControl.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorText: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  acceptZero: PropTypes.bool,
  acceptEmpty: PropTypes.bool,
  autoFocus: PropTypes.bool,
  endAdornment: PropTypes.string,
  regexValidation: PropTypes.any,
  type: PropTypes.string,
  disablePadding: PropTypes.bool,
  titleClassName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  showHelpOnErrorOnly: PropTypes.bool,
  helperText: PropTypes.string,
};

TextFormControl.defaultProps = {
  type: 'text',
  onError: () => {},
  onChange: () => {},
  variant: 'outlined',
};
