import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import MapCanvas from 'js/components/MapObjects/MapCanvas/MapCanvas';
import useShiftKeyDown from 'js/hooks/useShiftKeyDown';
import useMapEvent from 'js/components/DataLayer/hooks/useMapEvent';
import useIsMouseDown from 'js/hooks/useIsMouseDown';
import { useGoogleMap } from 'js/context/GoogleMapContext';
import NumberUtils from 'js/helpers/NumberUtils';
import { mapPolygonFromBboxPolygon } from '../MapPolygon';
import { squareFromLatLng } from '../../Prescription/PrescriptionUtils';

export const mapToCanvas = (lat, lng, width, height, bounds, allowOutOfBounds) => {
  // Figure out where in the image we are clicking (0 to 1)
  const { north, south, east, west } = bounds;

  const latRange = north - south;
  const lngRange = east - west;
  const lngPartial = (lng - west) / lngRange;
  const latPartial = (north - lat) / latRange;

  // Convert this to the x and y coordinates in the canvas
  let x = Math.round(width * lngPartial);
  let y = Math.round(height * latPartial);

  if (!allowOutOfBounds) {
    x = Math.min(width - 1, x);
    x = Math.max(0, x);
    y = Math.min(height - 1, y);
    y = Math.max(0, y);
  }

  return { x, y };
};

export const canvasToMap = (y, x, values, bounds) => {
  const height = values.length;
  const width = values[0].length;
  const { north, south, east, west } = bounds;

  let lng = NumberUtils.map(x, 0, width, west, east);
  let lat = NumberUtils.map(y, 0, height, north, south);

  return { lng, lat };
};

const DRAW_HOVER_POLYGON = false;

const MapCanvasClickable = ({
  bounds,
  width,
  height,
  clickPolygon,
  zIndex,
  onClick,
  onHover,
  overrideBrushSize,
  updateDependencies,
  clipPathPolygon,
  shouldScaleLatitude,
}) => {
  const googleMap = useGoogleMap();
  const isMouseDown = useRef(null);
  const shiftDown = useShiftKeyDown();
  const clickBounds = useRef(null);
  const contextRef = useRef(null);
  const mouseDownChanged = useIsMouseDown();

  const [hoverPoly, setHoverPoly] = useState(null);

  useEffect(() => {
    isMouseDown.current = mouseDownChanged;
  }, [mouseDownChanged]);

  const latLngToPixel = useCallback(
    (lat, lng, allowOutOfBounds) => {
      return mapToCanvas(lat, lng, width, height, bounds, allowOutOfBounds);
    },
    [bounds, width, height]
  );

  const handleOnClick = useCallback(
    (event) => {
      if (!onClick) {
        return;
      }

      let latLng = { lat: event.latLng.lat(), lng: event.latLng.lng() };

      if (
        !clickBounds.current ||
        google.maps.geometry.poly.containsLocation(event.latLng, clickBounds.current)
      ) {
        let point = latLngToPixel(latLng.lat, latLng.lng, false);
        let box = squareFromLatLng(latLng, overrideBrushSize);

        onClick(point, latLng, box);
      }
    },
    [clickBounds, onClick, latLngToPixel, overrideBrushSize]
  );

  const onMouseLeave = useCallback(() => {
    if (contextRef.current) {
      contextRef.current.clearRect(0, 0, width, height);
    }
  }, [contextRef, width, height]);

  const handleOnHover = useCallback(
    (event) => {
      let ctx = contextRef.current;

      if (!ctx) {
        return;
      }

      let latLng = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      let point = latLngToPixel(latLng.lat, latLng.lng, true);
      let { x, y } = point;

      if (x >= 0 && x < width && y >= 0 && y < height) {
        let box = squareFromLatLng(latLng, overrideBrushSize);
        onHover(box, ctx, width, height);

        DRAW_HOVER_POLYGON && setHoverPoly(box);

        if (shiftDown && isMouseDown.current) {
          if (
            !clickBounds.current ||
            google.maps.geometry.poly.containsLocation(event.latLng, clickBounds.current)
          ) {
            onClick(point, latLng, box);
          }
        }
      } else {
        onMouseLeave();
      }
    },
    [
      width,
      height,
      shiftDown,
      onClick,
      onHover,
      contextRef,
      latLngToPixel,
      overrideBrushSize,
      onMouseLeave,
      clickBounds,
      shouldScaleLatitude,
    ]
  );

  const onHoverCanvasDraw = useCallback((ctx) => {
    contextRef.current = ctx;
  }, []);

  useMapEvent(googleMap, 'click', handleOnClick);
  useMapEvent(googleMap, 'mouseout', onMouseLeave);
  useMapEvent(googleMap, 'mousemove', handleOnHover);

  useEffect(() => {
    googleMap.setOptions({ draggable: !shiftDown });
  }, [shiftDown, googleMap]);

  useEffect(() => {
    let path = clickPolygon
      ? clickPolygon.map((choord) => ({ lng: choord[0], lat: choord[1] }))
      : null;
    clickBounds.current = path ? new google.maps.Polygon({ paths: path }) : null;
  }, [clickPolygon]);

  return (
    <>
      <MapCanvas
        clipPathPolygon={clipPathPolygon}
        updateDependencies={updateDependencies}
        bounds={bounds}
        onDraw={onHoverCanvasDraw}
        zIndex={zIndex}
        width={width}
        height={height}
      />

      {mapPolygonFromBboxPolygon(hoverPoly, { disabled: true })}
    </>
  );
};

MapCanvasClickable.propTypes = {
  clipPathPolygon: PropTypes.array,
  updateDependencies: PropTypes.array,
  bounds: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  zIndex: PropTypes.number,
  overrideBrushSize: PropTypes.number,
  clickPolygon: PropTypes.array,
  onDraw: PropTypes.func,
  onHover: PropTypes.func,
  onClick: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

MapCanvasClickable.defaultProps = {
  onDraw: () => {},
  zIndex: 2,
};

export default memo(MapCanvasClickable);
