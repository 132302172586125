import React, { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import PrescriptionSplitView from 'js/components/Prescription/PrescriptionSplitView/PrescriptionSplitView';
import { usePrescriptionJob } from 'js/components/Prescription/PrescriptionJobContext';
import MapCanvas from 'js/components/MapObjects/MapCanvas/MapCanvas';

import { isSatelliteLayer, isSurveyLayer } from 'js/components/Prescription/PrescriptionJob';

import { PRESCRIPTION_INTERVAL_COLORS } from 'js/constants/PrescriptionConstants';
import MapCanvasClickable, {
  canvasToMap,
} from 'js/components/MapObjects/MapCanvas/MapCanvasClickable';
import MapFloatPanel from 'js/components/MapView/MapFloatPanel';
import PrescriptionOverrideList from 'js/components/Prescription/PrescriptionOverrideList/PrescriptionOverrideList';
import { connect } from 'react-redux';
import DataLayer from '../../DataLayer/DataLayer';
import FeaturePolygon from '../../DataLayer/FeaturePolygon';
import { yellow } from '@material-ui/core/colors';
import MapLegend from '../../MapLegend/MapLegend';
import WebAPIUtils from '../../../WebAPIUtils';
import { useFarm } from '../../../context/AccountContext';
import { useHookRef } from '../../../hooks/useHookRef';
import { getLayerConfig } from '../PrescriptionUtils';
import { Box } from '@material-ui/core';
import MapCanvasMiniatureContainer from '../../MapObjects/MapCanvas/MapCanvasMiniatureContainer';
import ViewModeConstants from '../../../constants/ViewModeConstants';
import HeightMapToggle from '../../MapObjects/HeightMap/HeightMapToggle';
import GoogleMap from '../../MapObjects/GoogleMap/GoogleMap';
import HeightMap from '../../MapObjects/HeightMap/HeightMap';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../../hooks/useFirebaseAnalytics';
import * as turf from '@turf/turf';
import {
  iterateRawValues,
  iterateValidOverrides,
  iterateValidValues,
} from '../../../helpers/SurveyUtils';
import { useSelectedSurvey, useSurveyLayerViewCapabilities } from '../../../context/SurveyContext';
import {
  Action,
  useActionSnackbarContext,
} from '../../ActionSnackbarHandler/ActionSnackbarHandler';
import { useLangFile } from '../../../context/LanguageContext';
import { getClassificationValues } from '../../../reducers/SurveyReducer';
import PrescriptionClassificationChangeDialog from '../Dialogs/PrescriptionClassificationChangeDialog';
import moment from 'moment-timezone';
import DateHandler from '../../DateHandler/DateHandler';
import { setDate } from '../../../reducers/FieldReducer';

const tinycolor = require('tinycolor2');

const boundsToPolygon = (bounds) => {
  return [
    [bounds.west, bounds.north],
    [bounds.east, bounds.north],
    [bounds.east, bounds.south],
    [bounds.west, bounds.south],
    [bounds.west, bounds.north], // end in the start point
  ];
};

const RGB_COLORS = PRESCRIPTION_INTERVAL_COLORS.map((hex) => tinycolor(hex).toRgb());

export function getIntervalColorFromPixel(value, intervals) {
  let color;

  for (let i = 0; i < intervals.length; i++) {
    let interval = intervals[i];
    if (value >= interval.min) {
      color = RGB_COLORS[i];
    }
  }
  return color;
}

export function getIntervalColorForClassificationIndex(index) {
  let color;
  if (index < RGB_COLORS.length) {
    color = RGB_COLORS[index];
  }
  return color;
}

function setPixel(imageData, x, y, color) {
  let { r, g, b, a } = color;
  let index = (x + y * imageData.width) * 4;
  imageData.data[index] = r;
  imageData.data[index + 1] = g;
  imageData.data[index + 2] = b;
  imageData.data[index + 3] = Math.floor(a * 255);
}

const mapStateToProps = (store) => ({
  fields: store.field.fields,
  selectedFieldDates: store.field.selectedFieldDates,
  images: store.field.images,
  viewMode: store.control.viewMode,
  overlays: store.overlay.overlays,
  selectedSurveyClassificationValues: store.survey.selectedSurveyClassificationValues,
  selectedDate: store.field.date,
});

const PrescriptionSplitViewContainer = ({
  dispatch,
  isOverriding,
  fields,
  viewMode,
  selectedFieldDates,
  overrideBrushValue,
  overrideBrushSize,
  onOverridesChanged,
  enableSurveys,
  enableNdvi,
  classificationsEnabled,
  setClassificationsEnabled,
  variationsEnabled,
  setVariationsEnabled,
  overlays,
  selectedSurveyClassificationValues,
  setClassificationsReset,
  selectedDate,
  datesWithImages,
}) => {
  const LangFile = useLangFile();
  const analytics = useFirebaseAnalytics();
  const { prescriptionJob } = usePrescriptionJob();
  const { addAction } = useActionSnackbarContext();

  const farm = useFarm();
  const farmId = useHookRef(farm.farmId);
  const legendRef = useRef();
  const bounds = useRef(prescriptionJob.bounds);
  const date = useRef(prescriptionJob.date);
  const survey = useRef(useSelectedSurvey());
  const field = useRef(fields.get(prescriptionJob.fieldId));
  const [imageURL, setImageURL] = useState(null);
  const clickPolygon = useRef(boundsToPolygon(bounds.current));
  const sourceValues = useRef(prescriptionJob.values);
  const canvasSize = useRef({
    height: sourceValues.current.length,
    width: sourceValues.current[0].length,
  });

  const [sourceLayer, setSourceLayer] = useState(prescriptionJob.layer);
  const [referenceValues, setReferenceValues] = useState({
    [prescriptionJob.layer]: prescriptionJob.values,
  });
  const [enableClassification, setEnableClassification] = useState(classificationsEnabled);

  const [multipleSelections, setMultipleSelections] = useState([]);
  const [showHeightMapLeft, setShowHeightMapLeft] = useState(false);
  const [showHeightMapRight, setShowHeightMapRight] = useState(false);
  const [shouldScaleLatitude, setShouldScaleLatitude] = useState(false);
  const [showClassificationDialog, setShowClassificationDialog] = useState(false);

  const layerCapabilities = useSurveyLayerViewCapabilities(
    sourceLayer,
    ViewModeConstants.PRESCRIPTION
  );
  const disableClassificationToggle =
    prescriptionJob.jobId &&
    prescriptionJob.layerType &&
    layerCapabilities.enableCustomClassification;

  useEffect(() => {
    setSourceLayer(prescriptionJob.layer);
  }, [prescriptionJob.layer]);

  useEffect(() => {
    sourceValues.current = prescriptionJob.values;
  }, [prescriptionJob.values]);

  useEffect(() => {
    if (isSurveyLayer(sourceLayer)) {
      let config = getLayerConfig(sourceLayer);
      let url = config.getImageUrl(
        prescriptionJob.assets,
        classificationsEnabled,
        variationsEnabled,
        ViewModeConstants.PRESCRIPTION
      );
      setImageURL(url);
      setShouldScaleLatitude(true);
    } else if (isSatelliteLayer(sourceLayer)) {
      if (prescriptionJob && prescriptionJob.assets) {
        setImageURL(prescriptionJob.assets[sourceLayer]);
        setShouldScaleLatitude(false);
      }
    }
  }, [sourceLayer, classificationsEnabled, variationsEnabled, prescriptionJob]);

  useEffect(() => {
    if (referenceValues[sourceLayer] == null) {
      new Promise(async (resolve, reject) => {
        let values = await WebAPIUtils.getLayerValues(
          farmId.current,
          field.current.fieldId,
          date.current,
          sourceLayer,
          survey.current,
          prescriptionJob.jobId
        );
        if (values != null) {
          resolve({
            [sourceLayer]: values,
          });
        } else {
          let layerName = getLayerConfig(sourceLayer).getName(LangFile);
          addAction(
            new Action(
              `failed-to-load-values-${sourceLayer}`,
              LangFile.PrescriptionContainer.failedToLoadValues.replace('%s', layerName),
              'warning',
              'filled'
            )
          );
        }
      }).then((res) => {
        setReferenceValues((current) => {
          let result = { ...current };
          if (res != null) {
            result = { ...result, ...res };
          }

          // only select valid/loaded layers
          setMultipleSelections(
            Object.keys(result).filter((l) => l !== sourceLayer && multipleSelections.includes(l))
          );

          return result;
        });
      });
    } else if (multipleSelections.includes(sourceLayer)) {
      setMultipleSelections(
        Object.keys(referenceValues).filter(
          (l) => l !== sourceLayer && multipleSelections.includes(l)
        )
      );
    }

    if (classificationsEnabled && layerCapabilities.enableCustomClassification) {
      if (selectedSurveyClassificationValues[sourceLayer] == null) {
        dispatch(
          getClassificationValues(
            farmId.current,
            field.current.fieldId,
            date.current,
            sourceLayer,
            survey.current,
            prescriptionJob.jobId
          )
        );
      }

      let missingClassifications = multipleSelections.filter(
        (layer) => !selectedSurveyClassificationValues[layer]
      );
      missingClassifications.map(async (layer, idx, arr) => {
        if (selectedSurveyClassificationValues[layer] == null) {
          dispatch(
            getClassificationValues(
              farmId.current,
              field.current.fieldId,
              date.current,
              layer,
              survey.current,
              prescriptionJob.jobId
            )
          );
        }
      });
    }
  }, [
    sourceLayer,
    referenceValues,
    classificationsEnabled,
    multipleSelections,
    selectedSurveyClassificationValues,
  ]);

  const handleSetShowHeightMapLeft = useCallback((value) => {
    setShowHeightMapLeft(value);
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_HEIGHT_MAP_LEFT, { shown: value });
  }, []);

  const handleSetShowHeightMapRight = useCallback((value) => {
    setShowHeightMapRight(value);
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_HEIGHT_MAP_RIGHT, { shown: value });
  }, []);

  const onVariationsEnabledChanged = useCallback((value) => {
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_SHOW_DETAILS, { shown: value });

    setVariationsEnabled(value);
    setClassificationsEnabled((curr) => {
      if (curr && value) {
        return false;
      }
      return curr;
    });
  }, []);

  const onClassificationsEnabledChanged = useCallback((value) => {
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_SHOW_DEMAND, { shown: value });

    if (layerCapabilities.enableCustomClassification) {
      setEnableClassification(value);
      setShowClassificationDialog(true);
    } else {
      setChangeDemandClassification(value);
    }
  }, []);

  const onHideClassificationChangeDialog = useCallback(() => {
    setShowClassificationDialog(false);
  }, []);

  const onApplyClassificationChange = useCallback(() => {
    setShowClassificationDialog(false);
    setChangeDemandClassification(enableClassification);
    setClassificationsReset(true);
  }, [enableClassification]);

  const setChangeDemandClassification = (change) => {
    setClassificationsEnabled(change);
    setVariationsEnabled((curr) => {
      if (curr && change) {
        return false;
      }
      return curr;
    });
  };

  const handleMultipleSelectionChanged = useCallback(
    (selections) => {
      setMultipleSelections(selections);
      if (selections.length > 0) {
        analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_MULTIPLE_SELECT, {
          selections: selections.join(', '),
        });

        let missing = selections.filter((layer) => !referenceValues[layer]);
        if (isSatelliteLayer(sourceLayer)) {
          missing.forEach((layer) => {
            setReferenceValues((current) => {
              return { ...current, ...{ [layer]: prescriptionJob.values } };
            });
          });
        } else {
          Promise.all(
            missing.map(async (layer, idx, arr) => {
              let values = await WebAPIUtils.getLayerValues(
                farmId.current,
                field.current.fieldId,
                date.current,
                layer,
                survey.current,
                prescriptionJob.jobId
              );

              if (values != null) {
                return {
                  [layer]: values,
                };
              } else {
                let layerName = getLayerConfig(layer).getName(LangFile);
                addAction(
                  new Action(
                    `failed-to-load-values-${layer}`,
                    LangFile.PrescriptionContainer.failedToLoadValues.replace('%s', layerName),
                    'warning',
                    'filled'
                  )
                );
              }
            })
          ).then((res) => {
            setReferenceValues((current) => {
              let result = { ...current };

              res.forEach((item) => {
                if (item != null) {
                  result = { ...result, ...item };
                }
              });

              // only select valid/loaded layers
              setMultipleSelections(
                Object.keys(result).filter((l) => l !== sourceLayer && selections.includes(l))
              );

              return result;
            });
          });
        }
      }
    },
    [referenceValues, prescriptionJob, LangFile, sourceLayer]
  );

  const drawPrescription = useCallback(
    (context: CanvasRenderingContext2D) => {
      let values = sourceValues.current;
      let intervals = prescriptionJob.intervals;
      let height = values.length;
      let width = values[0].length;
      let imageData = context.createImageData(width, height);

      if (
        layerCapabilities.enableCustomClassification &&
        classificationsEnabled &&
        selectedSurveyClassificationValues[sourceLayer]
      ) {
        const fineMappings = selectedSurveyClassificationValues[sourceLayer]['mappings']['FINE'];
        const values = selectedSurveyClassificationValues[sourceLayer]['values'];
        iterateRawValues(values, (x, y, value) => {
          const classification = Object.keys(fineMappings).find((key) =>
            fineMappings[key].includes(value)
          );
          const index = intervals.findIndex((interval) => {
            return interval.classification === classification;
          });
          const color = getIntervalColorForClassificationIndex(index);
          if (color) {
            setPixel(imageData, x, y, color);
          }
        });
      } else {
        iterateValidValues(values, (x, y, value) => {
          let color = getIntervalColorFromPixel(value, intervals);
          setPixel(imageData, x, y, color);
        });
      }

      context.putImageData(imageData, 0, 0, 0, 0, width, height);
    },
    [prescriptionJob.intervals]
  );

  const drawOverrides = useCallback(
    (context: CanvasRenderingContext2D) => {
      let overrides = prescriptionJob.overrides;
      let groups = prescriptionJob.overrideAreas;

      let height = overrides.length;
      let width = overrides[0].length;

      let imageData = context.createImageData(width, height);

      iterateValidOverrides(overrides, (x, y, value) => {
        let group = groups[value];

        if (group) {
          let color = tinycolor(group.color).toRgb();
          setPixel(imageData, x, y, color);
        }
      });

      context.putImageData(imageData, 0, 0, 0, 0, width, height);
    },
    [prescriptionJob.overrides, prescriptionJob.overrideAreas]
  );

  const handleDateSet = useCallback((date) => {
    let dateComponent = moment(date).format('YYYY-MM-DD');
    dispatch(setDate(dateComponent));
  }, []);

  const getContainedPixels = useCallback((boxPoly) => {
    const isContained = (x, y) => {
      let { lat, lng } = canvasToMap(y, x, sourceValues.current, bounds.current);
      let point = turf.point([lng, lat]);
      return turf.booleanPointInPolygon(point, boxPoly);
    };

    let startX = null,
      startY = null,
      endX = null,
      endY = null;

    iterateRawValues(sourceValues.current, (x, y) => {
      if (isContained(x, y)) {
        if (startX === null) {
          startX = x;
        }
        if (startY === null) {
          startY = y;
        }
        endX = Math.max(endX, x);
        endY = Math.max(endY, y);
      }
    });

    return { startX, startY, endX, endY };
  }, []);

  const onOverridesCanvasClicked = useCallback(
    (pixelXY, latLng, boxPoly) => {
      let values = sourceValues.current;
      let nextOverrides = prescriptionJob.overrides.map((row) => row.slice(0));

      let { startX, startY, endX, endY } = getContainedPixels(boxPoly);

      for (let x = startX; x < endX; x++) {
        for (let y = startY; y < endY; y++) {
          let valueRow = values[y];
          let value = valueRow && valueRow[x];

          if (value !== null && value > 0) {
            nextOverrides[y][x] = overrideBrushValue;
          }
        }
      }

      onOverridesChanged(nextOverrides);
    },
    [onOverridesChanged, overrideBrushValue, prescriptionJob.overrides]
  );

  const onOverridesCanvasHovered = useCallback(
    (boxPoly, ctx, width, height) => {
      let { startX, startY, endX, endY } = getContainedPixels(boxPoly);

      const prevFill = ctx.fillStyle;

      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = 'rgba(35,35,200,0.2)';
      ctx.fillRect(startX, startY, endX - startX, endY - startY);
      ctx.fillStyle = prevFill;
    },
    [onOverridesChanged, overrideBrushValue, prescriptionJob.overrides]
  );

  return (
    <PrescriptionSplitView
      overrideMode={isOverriding}
      overrideBrushValue={overrideBrushValue}
      bounds={bounds.current}>
      {(leftMap) => (
        <Fragment>
          <MapFloatPanel zIndex={0} top={0} left={0} right={0} bottom={0}>
            <GoogleMap mapType={'transparent'} disableInteraction={true} syncWithMap={leftMap}>
              <HeightMap shown={showHeightMapLeft} overlays={overlays} />
            </GoogleMap>
          </MapFloatPanel>

          <MapFloatPanel zIndex={200} top={8} left={8}>
            <MapLegend
              viewMode={viewMode}
              showSatelliteImagery={isSatelliteLayer(sourceLayer)}
              showSoilSurveys={isSurveyLayer(sourceLayer)}
              selectedFieldHasImage={enableNdvi}
              selectedFieldHasSurvey={enableSurveys}
              selectedLayer={sourceLayer}
              surveys={[{ surveyId: 'teasta', newImages: prescriptionJob.images }]}
              values={referenceValues[sourceLayer]}
              dates={selectedFieldDates}
              renderDateHandler={() => {
                if (isSatelliteLayer(sourceLayer)) {
                  return (
                    <DateHandler
                      date={selectedDate}
                      dates={datesWithImages}
                      handleDateSet={handleDateSet}
                    />
                  );
                }
              }}
              networkEnabled={false}
              variationsEnabled={variationsEnabled}
              classificationsEnabled={classificationsEnabled}
              surveyClassificationValues={selectedSurveyClassificationValues}
              onSetSatelliteImageType={setSourceLayer}
              onSetSurveyType={setSourceLayer}
              onVariationsEnabledChanged={onVariationsEnabledChanged}
              onClassificationsEnabledChanged={onClassificationsEnabledChanged}
              enableMultipleSelect={true}
              multipleSelections={multipleSelections}
              onMultipleSelectChanged={handleMultipleSelectionChanged}
              disableDemandClassification={disableClassificationToggle}
            />

            <PrescriptionClassificationChangeDialog
              newState={enableClassification}
              onCancel={onHideClassificationChangeDialog}
              onApply={onApplyClassificationChange}
              open={showClassificationDialog}
            />

            <Box
              ref={legendRef}
              width={'100%'}
              display={'flex'}
              flexDirection={'column'}
              style={{ pointerEvents: 'none' }}
            />
          </MapFloatPanel>

          <MapFloatPanel zIndex={200} top={8} right={8}>
            <HeightMapToggle
              onToggle={handleSetShowHeightMapLeft}
              showHeightMap={showHeightMapLeft}
            />
          </MapFloatPanel>

          <DataLayer
            setStyle={(feature) => {
              return {
                strokeColor: yellow['A400'],
                fillOpacity: 0,
                clickable: false,
              };
            }}>
            <FeaturePolygon coords={field.current.polygon.coordinates} />
          </DataLayer>

          <MapCanvasMiniatureContainer
            viewMode={viewMode}
            legendRef={legendRef.current}
            isOverriding={isOverriding}
            imageURL={imageURL}
            field={field.current}
            selectedLayer={sourceLayer}
            multipleSelections={multipleSelections}
            classificationsEnabled={classificationsEnabled}
            onMultipleSelectChanged={handleMultipleSelectionChanged}
            variationsEnabled={variationsEnabled}
            values={referenceValues}
            classificationValues={selectedSurveyClassificationValues}
          />

          <MapCanvas
            zIndex={2}
            clipPathPolygon={field.current.polygon.coordinates[0]}
            updateDependencies={[drawOverrides]}
            bounds={bounds.current}
            width={canvasSize.current.width}
            height={canvasSize.current.height}
            onDraw={drawOverrides}
          />

          {isOverriding && (
            <MapCanvasClickable
              zIndex={6}
              shouldScaleLatitude={shouldScaleLatitude}
              clipPathPolygon={field.current.polygon.coordinates[0]}
              bounds={bounds.current}
              width={canvasSize.current.width}
              height={canvasSize.current.height}
              overrideBrushSize={overrideBrushSize}
              clickPolygon={clickPolygon.current}
              onClick={onOverridesCanvasClicked}
              onHover={onOverridesCanvasHovered}
            />
          )}
        </Fragment>
      )}

      {(rightMap) => (
        <Fragment>
          <MapFloatPanel zIndex={200} top={8} right={8}>
            <HeightMapToggle
              onToggle={handleSetShowHeightMapRight}
              showHeightMap={showHeightMapRight}
            />
          </MapFloatPanel>

          <MapFloatPanel zIndex={0} top={0} left={0} right={0} bottom={0}>
            <GoogleMap mapType={'transparent'} disableInteraction={true} syncWithMap={rightMap}>
              <HeightMap shown={showHeightMapRight} overlays={overlays} />
            </GoogleMap>
          </MapFloatPanel>

          <DataLayer
            setStyle={(feature) => {
              return {
                strokeColor: yellow['A400'],
                fillOpacity: 0,
                clickable: false,
              };
            }}>
            <FeaturePolygon coords={field.current.polygon.coordinates} />
          </DataLayer>

          <MapCanvas
            updateDependencies={[drawPrescription]}
            clipPathPolygon={field.current.polygon.coordinates[0]}
            bounds={bounds.current}
            width={canvasSize.current.width}
            height={canvasSize.current.height}
            onDraw={drawPrescription}
          />

          <MapCanvas
            updateDependencies={[drawOverrides]}
            clipPathPolygon={field.current.polygon.coordinates[0]}
            bounds={bounds.current}
            width={canvasSize.current.width}
            height={canvasSize.current.height}
            onDraw={drawOverrides}
          />

          {isOverriding && (
            <Fragment>
              <MapCanvasClickable
                clipPathPolygon={field.current.polygon.coordinates[0]}
                bounds={bounds.current}
                shouldScaleLatitude={shouldScaleLatitude}
                width={canvasSize.current.width}
                height={canvasSize.current.height}
                overrideBrushSize={overrideBrushSize}
                clickPolygon={clickPolygon.current}
                onClick={onOverridesCanvasClicked}
                onHover={onOverridesCanvasHovered}
              />

              <MapFloatPanel left={8} top={16}>
                <PrescriptionOverrideList onOverridesUpdated={onOverridesChanged} />
              </MapFloatPanel>
            </Fragment>
          )}
        </Fragment>
      )}
    </PrescriptionSplitView>
  );
};

PrescriptionSplitViewContainer.propTypes = {
  isOverriding: PropTypes.bool,
  enableNdvi: PropTypes.bool,
  enableSurveys: PropTypes.bool,
  classificationsEnabled: PropTypes.bool,
  setClassificationsEnabled: PropTypes.func,
  setClassificationsReset: PropTypes.func,
  variationsEnabled: PropTypes.bool,
  setVariationsEnabled: PropTypes.func,
  overrideBrushValue: PropTypes.number,
  overrideBrushSize: PropTypes.number,
  onOverridesChanged: PropTypes.func,
  datesWithImages: PropTypes.array,
};

PrescriptionSplitViewContainer.defaultProps = {};

export default memo(connect(mapStateToProps)(PrescriptionSplitViewContainer));
