// @flow

import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { grey } from '@material-ui/core/colors';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import { PRESCRIPTION_METERING, PRESCRIPTION_UNIT } from 'js/constants/PrescriptionConstants';
import TextFormControl from 'js/components/UI-Elements/TextFormControl.jsx';
import SelectFormControl from 'js/components/UI-Elements/SelectFormControl.jsx';
import Grid from '@material-ui/core/Grid';
import { useLangFile } from 'js/context/LanguageContext';
import { MetaTypes } from 'js/components/Prescription/PrescriptionJob';
import PrescriptionAutoAdjustWarningDialog from 'js/components/Prescription/Dialogs/PrescriptionAutoAdjustWarningDialog';
import {
  DEFAULT_MAX_PRESCRIPTION,
  SQUARE_METER_COVERSION_VALUE,
} from 'js/components/Prescription/PrescriptionUtils';
import TextFormStateControl, { TEXT_FORM_STATE } from '../../../UI-Elements/TextFormStateControl';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import CachedIcon from '@material-ui/icons/Cached';

const validRegex = /^[a-zA-Z0-9]+$/;
const warningRegex = /^[a-zA-Z0-9æøåÆØÅ\-_()%,]+$/;

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
    color: grey['600'],
    display: 'flex',
    textAlign: 'left',
    flex: 1,
    flexDirection: 'column',
    justifyItems: 'center',
    fontSize: '0.8em',
  },
  control: {
    padding: theme.spacing(1),
  },
  label: {
    fontSize: '1rem',
  },
  '@media (max-width: 1024px), (max-height: 800px)': {
    control: {
      padding: [[0, theme.spacing(1) / 2]],
    },
    label: {
      fontSize: '0.75rem',
    },
  },
});

const PrescriptionSettings = ({
  classes,
  onError,
  onUpdateMaxPrescription,
  onUpdateUnit,
  fieldSize,
  metering,
  unit,
  metaType,
  jobName,
  limeInfo,
  onUpdateJobName,
  onUpdateMetaType,
  maxPrescription,
  onUpdateMetering,
  onUpdateLimeInfo,
}) => {
  const LangFile = useLangFile();
  const [currentMaxPrescription, setCurrentMaxPrescription] = useState('');
  const [pendingMetaType, setPendingMetaType] = useState(null);
  const [limeSupplierEfficiency, setLimeSupplierEfficency] = useState(null);
  const [useManualEfficiencyInput, setUseManualEfficiencyInput] = useState(false);

  const updateLimeSupplierEfficiency = (limeSupplierEfficiency) => {
    setLimeSupplierEfficency(limeSupplierEfficiency);
    onUpdateLimeInfo({ efficiency: limeSupplierEfficiency });
  };

  const updateLimeEfficiency = (limeEfficiency) => {
    setLimeSupplierEfficency(null);
    onUpdateLimeInfo({ efficiency: limeEfficiency });
  };

  useEffect(() => {
    // Potentially map from TOTAL to HA
    let max;
    switch (metering) {
      case PRESCRIPTION_METERING.HA:
        max = maxPrescription / fieldSize;
        break;
      case PRESCRIPTION_METERING.SQUARE_METER:
        max = maxPrescription / (fieldSize * SQUARE_METER_COVERSION_VALUE);
        break;
      default:
        max = maxPrescription;
    }
    setCurrentMaxPrescription(max);
  }, [maxPrescription, metering, fieldSize]);

  let maxPrescriptionEndAdornment;
  switch (unit) {
    case PRESCRIPTION_UNIT.LITER:
      maxPrescriptionEndAdornment = LangFile.PrescriptionSettings.unit.liters.short;
      break;
    case PRESCRIPTION_UNIT.PIECES_M2:
      maxPrescriptionEndAdornment = LangFile.PrescriptionSettings.unit.pieces.short;
      break;
    default:
      maxPrescriptionEndAdornment = LangFile.PrescriptionSettings.unit.kilogram.short;
  }

  let maxPrescriptionLabel;
  switch (metering) {
    case PRESCRIPTION_METERING.HA:
      maxPrescriptionLabel =
        LangFile.PrescriptionSettings.maxAllocation.maxAllocationDescriptionPerHectare;
      maxPrescriptionEndAdornment += ` / ha`;
      break;
    case PRESCRIPTION_METERING.SQUARE_METER:
      maxPrescriptionLabel =
        LangFile.PrescriptionSettings.maxAllocation.maxAllocationDescriptionPerSquareMeter;
      maxPrescriptionEndAdornment += ` / m^2`;
      break;
    default:
      maxPrescriptionLabel = LangFile.PrescriptionSettings.maxAllocation.maxAllocationDescription;
      maxPrescriptionEndAdornment += ` total`;
  }

  const handleOnError = (key) =>
    useCallback(
      (error) => {
        onError(key, error);
      },
      [onError]
    );

  const handleOnUpdateJobName = useCallback(
    (value) => {
      onUpdateJobName(value);
    },
    [onUpdateJobName]
  );

  const handleOnUpdateUnit = useCallback(
    (value) => {
      onUpdateUnit(value);
    },
    [onUpdateUnit]
  );

  const handleOnUpdateMetaType = useCallback(
    (value) => {
      if (value !== pendingMetaType) {
        if (value === MetaTypes.SPOT_SPRAYING) {
          setPendingMetaType(value);
          return;
        }
      }

      onUpdateMetaType(value);

      const metaTypeToUnit = {
        [MetaTypes.FERTILIZING]: PRESCRIPTION_UNIT.KILOGRAM,
        [MetaTypes.SEEDING]: PRESCRIPTION_UNIT.KILOGRAM,
        [MetaTypes.LIME]: PRESCRIPTION_UNIT.KILOGRAM,
        [MetaTypes.SPRAYING]: PRESCRIPTION_UNIT.LITER,
        [MetaTypes.SPOT_SPRAYING]: PRESCRIPTION_UNIT.LITER,
        [MetaTypes.LEGACY]: PRESCRIPTION_UNIT.LITER,
      };

      const unit = metaTypeToUnit[value];

      handleOnUpdateUnit(unit);
    },
    [onUpdateMetaType, pendingMetaType, handleOnUpdateUnit]
  );

  const handleOnCancelMetaType = useCallback(() => {
    setPendingMetaType(null);
  }, []);

  const handleOnUpdateMaxPrescription = useCallback(
    (value) => {
      // Potentially map from HA to TOTAL
      let max;
      switch (metering) {
        case PRESCRIPTION_METERING.HA:
          max = value * fieldSize;
          break;
        case PRESCRIPTION_METERING.SQUARE_METER:
          max = value * (fieldSize * SQUARE_METER_COVERSION_VALUE);
          break;
        default:
          max = value;
      }

      onUpdateMaxPrescription(max);
    },
    [onUpdateMaxPrescription, metering, fieldSize]
  );

  const handleOnUpdateMetering = useCallback(
    (event) => {
      onUpdateMetering(event.target.value);
    },
    [onUpdateJobName]
  );

  const state = useCallback((val) => {
    if (val === null || val === undefined) {
      return {
        state: TEXT_FORM_STATE.INVALID,
        message: LangFile.PrescriptionSettings.name.errorText,
      };
    } else if (val === '') {
      return {
        state: TEXT_FORM_STATE.EMPTY,
        message: null,
      };
    } else if (validRegex.test(val)) {
      return {
        state: TEXT_FORM_STATE.VALID,
        message: null,
      };
    } else if (warningRegex.test(val)) {
      return {
        state: TEXT_FORM_STATE.WARNING,
        message: LangFile.PrescriptionSettings.name.warningText,
      };
    }
    return {
      state: TEXT_FORM_STATE.INVALID,
      message: LangFile.PrescriptionSettings.name.invalidText,
    };
  }, []);

  const unitOptions =
    metaType === MetaTypes.SEEDING
      ? [
          {
            value: PRESCRIPTION_UNIT.KILOGRAM,
            label: LangFile.PrescriptionSettings.unit.kilogram.name,
          },
          {
            value: PRESCRIPTION_UNIT.PIECES_M2,
            label: LangFile.PrescriptionSettings.unit.pieces.name,
          },
        ]
      : [
          {
            value: PRESCRIPTION_UNIT.KILOGRAM,
            label: LangFile.PrescriptionSettings.unit.kilogram.name,
          },
          {
            value: PRESCRIPTION_UNIT.LITER,
            label: LangFile.PrescriptionSettings.unit.liters.name,
          },
        ];

  const enableUnitSelection = metaType === MetaTypes.FERTILIZING || metaType === MetaTypes.SEEDING;

  const onlyMaxInput = (
    <Grid item xs={8} style={{ paddingBottom: 16 }}>
      <TextFormControl
        value={Number.isNaN(currentMaxPrescription) ? '' : currentMaxPrescription}
        type={'number'}
        required={true}
        placeholder={DEFAULT_MAX_PRESCRIPTION}
        className={classes.control}
        title={LangFile.PrescriptionSettings.maxAllocation.title}
        label={maxPrescriptionLabel}
        endAdornment={maxPrescriptionEndAdornment}
        errorText={LangFile.PrescriptionSettings.maxAllocation.errorText}
        onError={handleOnError('maxPrescription')}
        onUpdate={handleOnUpdateMaxPrescription}>
        <RadioGroup
          aria-label={'position'}
          name={'position'}
          value={metering}
          onChange={handleOnUpdateMetering}
          row>
          <FormControlLabel
            value={
              unit === PRESCRIPTION_UNIT.PIECES_M2
                ? PRESCRIPTION_METERING.SQUARE_METER
                : PRESCRIPTION_METERING.HA
            }
            control={<Radio color="primary" />}
            classes={{ label: classes.label }}
            label={
              unit === PRESCRIPTION_UNIT.PIECES_M2
                ? LangFile.PrescriptionSettings.maxAllocation.perSquareMeter
                : LangFile.PrescriptionSettings.maxAllocation.perHectare
            }
            labelPlacement="end"
          />
          <FormControlLabel
            value={PRESCRIPTION_METERING.TOTAL}
            control={<Radio color="primary" />}
            classes={{ label: classes.label }}
            label={LangFile.PrescriptionSettings.maxAllocation.entireField}
            labelPlacement="end"
          />
        </RadioGroup>
      </TextFormControl>
    </Grid>
  );

  const limeInfoSuppliers = [
    {
      value: 40,
      label: 'Hansens kalk (40%)',
    },
    {
      value: 50,
      label: 'Egå kalk (50%)',
    },
    {
      value: 97,
      label: 'Hennebergs kalkservice (97%)',
    },
  ];

  const limeJobInputs = (
    <Grid container item xs={8}>
      <Grid item xs={12} style={{ paddingLeft: '8px' }}>
        <Typography variant={'h6'}>{LangFile.PrescriptionSettings.limeInfo.title}</Typography>
      </Grid>
      <Grid item xs={4}>
        <TextFormControl
          value={limeInfo && limeInfo.min ? limeInfo.min : ''}
          type={'number'}
          label={LangFile.PrescriptionSettings.limeInfo.min.label}
          onChange={(value) => onUpdateLimeInfo({ min: value })}
          placeholder={LangFile.PrescriptionSettings.limeInfo.min.placeholder}
          min={0}
          max={2.99}
          helperText={
            limeInfo && limeInfo.min && (limeInfo.min > 2.99 || limeInfo.min < 0)
              ? LangFile.PrescriptionSettings.limeInfo.min.errorText
              : ''
          }
          acceptZero={true}
        />
      </Grid>

      <Grid item xs={4}>
        <TextFormControl
          value={limeInfo && limeInfo.max ? limeInfo.max : ''}
          type={'number'}
          label={LangFile.PrescriptionSettings.limeInfo.max.label}
          onChange={(value) => onUpdateLimeInfo({ max: value })}
          placeholder={LangFile.PrescriptionSettings.limeInfo.max.placeholder}
          min={3}
          max={6}
          helperText={
            limeInfo && limeInfo.max && (limeInfo.max > 6 || limeInfo.max < 3)
              ? LangFile.PrescriptionSettings.limeInfo.max.errorText
              : ''
          }
          acceptZero={true}
        />
      </Grid>

      <Grid item xs={4}>
        <SelectFormControl
          className={classes.control}
          label={LangFile.PrescriptionSettings.limeInfo.tolerance.label}
          value={limeInfo && limeInfo.tolerance ? limeInfo.tolerance : 1}
          onChange={(value) => onUpdateLimeInfo({ tolerance: value })}
          placeholder={LangFile.PrescriptionSettings.limeInfo.max.placeholder}
          options={[
            {
              value: 0.95,
              label: `${LangFile.PrescriptionSettings.limeInfo.tolerance.options.tolerant} (AR = 0.95)`,
            },
            {
              value: 1,
              label: `${LangFile.PrescriptionSettings.limeInfo.tolerance.options.middle} (AR = 1.00)`,
            },
            {
              value: 1.05,
              label: `${LangFile.PrescriptionSettings.limeInfo.tolerance.options.sensitive} (AR = 1.05)`,
            },
          ]}
        />
      </Grid>

      <Grid item xs={6}>
        {useManualEfficiencyInput === false && (
          <SelectFormControl
            className={classes.control}
            label={LangFile.PrescriptionSettings.limeInfo.efficiency.supplier.label}
            value={limeSupplierEfficiency}
            onChange={updateLimeSupplierEfficiency}
            placeholder={LangFile.PrescriptionSettings.limeInfo.efficiency.supplier.placeholder}
            options={limeInfoSuppliers}
          />
        )}

        {useManualEfficiencyInput && (
          <TextFormControl
            value={limeInfo && limeInfo.efficiency ? limeInfo.efficiency : ''}
            type={'number'}
            label={LangFile.PrescriptionSettings.limeInfo.efficiency.manual.label}
            onChange={updateLimeEfficiency}
            placeholder={LangFile.PrescriptionSettings.limeInfo.efficiency.manual.placeholder}
            min={0}
            max={100}
            acceptZero={true}
            helperText={
              limeInfo &&
              limeInfo.efficiency &&
              (limeInfo.efficiency > 100 || limeInfo.efficiency < 0)
                ? LangFile.PrescriptionSettings.limeInfo.efficiency.manual.errorText
                : ''
            }
          />
        )}
      </Grid>

      <Grid container alignItems="flex-end" item xs={6} style={{ paddingBottom: '12px' }}>
        <Grid item>
          <Button
            onClick={() => setUseManualEfficiencyInput(!useManualEfficiencyInput)}
            variant={'outlined'}>
            {useManualEfficiencyInput
              ? LangFile.PrescriptionSettings.limeInfo.efficiency.buttonTextSupplier
              : LangFile.PrescriptionSettings.limeInfo.efficiency.buttonTextManual}
            {<CachedIcon style={{ marginLeft: '8px' }}></CachedIcon>}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={8} style={{ paddingBottom: 16 }}>
            <TextFormStateControl
              autoFocus
              value={jobName}
              required={true}
              className={classes.control}
              title={LangFile.PrescriptionSettings.name.title}
              label={LangFile.PrescriptionSettings.name.label}
              placeholder={LangFile.PrescriptionSettings.name.placeholder}
              onError={handleOnError('jobName')}
              helperText={LangFile.PrescriptionSettings.name.helperText}
              onUpdate={handleOnUpdateJobName}
              currentState={state}
              errorStates={[TEXT_FORM_STATE.INVALID, TEXT_FORM_STATE.EMPTY]}
            />
          </Grid>
          <Grid xs={4} style={{ paddingBottom: 16 }}>
            <SelectFormControl
              className={classes.control}
              title={LangFile.PrescriptionSettings.jobType}
              label={LangFile.PrescriptionSettings.sprayTaskLabel}
              value={metaType}
              onChange={handleOnUpdateMetaType}
              options={[
                {
                  value: MetaTypes.FERTILIZING,
                  label: LangFile.PrescriptionJob.metaTypes.fertilizing,
                },
                {
                  value: MetaTypes.SPRAYING,
                  label: LangFile.PrescriptionJob.metaTypes.spraying,
                },
                {
                  value: MetaTypes.SPOT_SPRAYING,
                  label: LangFile.PrescriptionJob.metaTypes.spotSpraying,
                },
                {
                  value: MetaTypes.SEEDING,
                  label: LangFile.PrescriptionJob.metaTypes.seeding,
                },
                {
                  value: MetaTypes.LIME,
                  label: LangFile.PrescriptionJob.metaTypes.lime,
                  disabled: process.env.NODE_ENV && process.env.NODE_ENV !== 'development',
                },
              ]}
            />
          </Grid>

          {metaType !== MetaTypes.LIME ? onlyMaxInput : limeJobInputs}

          <Grid item xs={4}>
            <SelectFormControl
              className={classes.control}
              disabled={!enableUnitSelection}
              title={LangFile.PrescriptionSettings.unit.title}
              label={LangFile.PrescriptionSettings.unit.label}
              value={unit}
              onChange={handleOnUpdateUnit}
              options={unitOptions}
            />
          </Grid>
        </Grid>
      </div>

      <PrescriptionAutoAdjustWarningDialog
        pendingMetaType={pendingMetaType}
        onProceed={handleOnUpdateMetaType}
        onCancel={handleOnCancelMetaType}
      />
    </Fragment>
  );
};

export default memo(withStyles(styles)(PrescriptionSettings));

PrescriptionSettings.propTypes = {
  maxPrescription: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  metering: PropTypes.string,
  unit: PropTypes.string,
  jobName: PropTypes.string,
  metaType: PropTypes.string,
  fieldSize: PropTypes.number,
  onError: PropTypes.func,
  onUpdateMetaType: PropTypes.func,
  onUpdateMaxPrescription: PropTypes.func,
  onUpdateMetering: PropTypes.func,
  onUpdateJobName: PropTypes.func,
  onUpdateUnit: PropTypes.func,
};

PrescriptionSettings.defaultProps = {
  // Settings default to null to avoid rendering, if the setting is not passed down from the container.
  metering: null,
  unit: null,
  jobName: null,
  task: null,
};
