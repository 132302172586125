// @flow

import React, { memo } from 'react';
import { useLangFile } from 'js/context/LanguageContext';

import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { usePrescriptionJob } from 'js/components/Prescription/PrescriptionJobContext';

const PrescriptionAdjustButton = (props: PrescriptionAdjustButton.propTypes) => {
  const LangFile = useLangFile();
  const { prescriptionJob } = usePrescriptionJob();

  let adjustmentRequired = false;
  if (prescriptionJob.intervals.length > 1) {
    const difference =
      Number(prescriptionJob.totalPrescription) > Number(prescriptionJob.maxPrescription)
        ? Number(prescriptionJob.totalPrescription) - Number(prescriptionJob.maxPrescription)
        : Number(prescriptionJob.maxPrescription) - Number(prescriptionJob.totalPrescription);
    adjustmentRequired = !(difference < 0.01 && difference > -0.01);
  }

  return (
    adjustmentRequired && (
      <Box ml={1}>
        <Button variant={'contained'} color={'secondary'} onClick={props.onClick}>
          {LangFile.PrescriptionAdjustButton.label}
        </Button>
      </Box>
    )
  );
};

PrescriptionAdjustButton.propTypes = {
  onClick: PropTypes.func,
};

export default memo(PrescriptionAdjustButton);
